// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";


$theme-colors: (
  "primary": #00aeff,
);


// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'global';
@import 'profile';
@import 'components';
@import 'rr-badge';

@import '~bootstrap4-toggle/css/bootstrap4-toggle.min.css';
.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  justify-content: start;
}

@font-face {
  font-family: 'porter_ft_roundlight';
  src: url('/storage/fonts/porterftround-light-webfont.woff2') format('woff2'),
    url('/storage/fonts/porterftround-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'porter_ft_roundregular';
  src: url('/storage/fonts/porterftround-regular-webfont.woff2') format('woff2'),
    url('/storage/fonts/porterftround-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

#cwrap {
  font-family: 'levenim_mtregular';
  /* this line is edited */
  background-color: #5d5d5d;
  margin: 0px;
  padding: 20px;
  text-align: center;
  width: 100%;
  word-break: break-word;
}

#cwrap .triangle-shape {
  display: none;

  @media screen and (min-width: 991px) {
    display: initial;
  }
}

#cwrap #cart #cwrap {
  padding: 0;
}

/* edited */
.rRbutton {
  color: #309fd8 !important;
  font-size: 36px;
}

/* edited */

.rRbutton .btn-light {

  font-size: 28pt;



}

.mr-sm-2,
.mx-sm-2 {
  margin-right: 0.5rem !important;
  font-size: 28pt;
  color: #309fd8;
}

.navbar-brand {
  color: #309fd8;
  display: inline-block;
  margin-right: 0rem;
  padding-top: 0.32rem;
  padding-bottom: 0.32rem;
  font-size: 30pt;
  line-height: inherit;
  white-space: nowrap;
  
  @media screen and (min-width:  991px) {
    margin-right: 1rem;
    font-size: 36pt;
  }
}

.navbar-right {
  width: 100%;

  @media screen and (min-width: 991px) {
    width: auto;  
  }
}

.btn-light {
  font-size: 28pt;
  color: #309fd8;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #309fd8;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

#navbar {
  background-color: lightgrey;
  text-align: center;
}

.navibar {
  text-align: center
}

/* edited css */
.menubar {
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
  border: solid lightgray 2px;
  border-radius: 15px;
  word-break: break-word;
  overflow-wrap: break-word;

  @media screen and (min-width: 993px) {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;  
  }
}

@include media-breakpoint-up(md) {

  // .menubar {
  //   font-size: 16px;
  // }
}

.active {
  background-color: white;
  border-radius: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #309fd8 !important;
  position: relative;
}

.triangle-down {
  position: absolute;
  right: 8px;
  bottom: 5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #309fd8;
}

.menubar .item {
  cursor: pointer;
  border-radius: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: lightgrey;
}

.line {
  border-left: solid lightgrey 2px;
  border-right: solid lightgrey 2px;
}

.box {
  width: 100%;
  
  background-color: white;
  font-size: 14px;
  padding: 10px;
  border: solid #309fd8 3px;
  box-shadow: 2px 2px 10px;
  margin-bottom: 10px;

  @media screen and(min-width: 991px) {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 19px;
    padding: 20px;
  }
}

.userimg {
  background-size: contain;
  width: 100px;
  max-width: 150px;
  vertical-align: middle;
  height: 100px;
  max-height: 150px;
  border-radius: 50%;
}

.prothumb {
  width: 100%;
  margin-bottom: 12px;
}

.icons {
  width: 35px;
  height: 35px;
  border-radius: 50%;

  @media screen and (min-width: 928px) {
    width: 55px;
    height: 55px;
  }

  &.irate {
    @media screen and (max-width: 928px) {
      height: 2vh;
      max-width: 5vw;
    }
  }
}

.irate {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 45px;

  @media screen and (min-width: 930px) {
    height: 45px;
  }
}

.rate {
  color: #309fd8;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center
}

.tagname {
  font-size: 28px;
}


.review {
  font-size: 40px;
}

@media screen and (max-width: 930px) {
  .tagname {
    font-size: 20px;
  }

  .irate {
    max-width: 25px;
    max-height: 25px;
  }

  .icons {
    max-width: 35px;
  }

  .rate {
    font-size: 15px;
  }
}

/* css edited */



.review {
  font-size: 35px;
}

.imageProduct {
  max-width: 350px;
  margin-bottom: 10px;
  width: 100%;
}


/* START OF FRIEND CSS */
.selectedItem {
  background-color: grey;
  color: white;
  padding: 6px;
}

/* END  OF FRIEND CSS */

/* START OF FRIEND CSS */

.friendwrap {
  text-align: center;
  margin-top: 20px;

  @media screen and (min-width: 991px) {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;  
    margin-top: 0;
  }
}

#friend {
  font-size: 14px;

}

.addfriend {
  background-color: rgb(0, 174, 255);
  border: solid white 1px;
  border-radius: 5px;
  color: white;
  font-size: 20px;
  padding: 7px;
  cursor: pointer;
}

.friendtab {
  background-color: white;
  border: solid lightgray 2px;
  border-radius: 5px;
  margin: 0px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;

}

.click {
  background-color: white;
  border: solid lightgray 1px;
  border-radius: 5px;
  color: #309fd8;
  font-size: 20px;
  padding: 5px;
}

.unclick {
  color: #309fd8;
  font-size: 20px;
  padding: 5px;
}

.pplcontent {
  background-color: white;
  font-size: 16px;
  border: solid #309fd8 3px;
  -webkit-box-shadow: 2px 2px 10px;
  box-shadow: 2px 2px 10px;
  border-radius: 10px;
}

.myfriend {
  font-size: 20px;
  text-align: left;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
}

.overflow {
  border-top: solid gray 1px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  margin: 0px;
  padding: 10px;
  position: relative;
}

.friendavatar {
  background-size: contain;
  width: 100%;
  max-width: 150px;
  vertical-align: middle;
  height: 100%;
  max-height: 150px;
  border-radius: 50%;
}

.frnddetails {
  text-align: left;
  color: rgb(94, 94, 94);
  display: flex;
}

.friend-interests {
  @media screen and (max-width: 991px) {
    max-height: 100px;
    overflow: auto;
  }
}

.col-sm-6.frnddetails {
  flex-direction: column;

  @media screen and (min-width: 991px) {
    flex-direction: row;
  }
}

.friendbox {
  border-bottom: solid gray 1px;
  margin: 5px;
  padding-bottom: 13px;
}

.iicon {
  max-width: 45px;
  max-height: 45px;
  width: 100%;
  margin: 1px;
}

.fblogin {
  background: white;
  cursor: pointer;
  padding: 10px;
}

.fblogo {
  display: block;
  width: 100%;
  max-width: 50px;
  margin: 0px;
  padding: 5px;
  margin-right: 10px;
}

.fbbg {
  font-size: 20px;
  font-family: arial;
  color: white;
  background-color: #309fd8;
  border-radius: 5px;
  margin: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.wrapbox {
  background: white;
  color: black;
  cursor: pointer;
  padding: 3px 25px;
  font-size: 27px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.emailicon {
  max-width: 50px;
  width: 100%;
}

.cpicon {
  max-height: 60px;
  margin-right: 15px;
}

.triangle-shape {
  position: relative;
  background-size: contain;
  margin-top: -20px;
  margin-bottom: -4px;
  margin-left: -28%;
  max-height: 50px;
  width: 20%;
  z-index: 9999;
}

@media screen and (max-width: 1450px) {

  .wrapbox {
    background: white;
    color: black;
    cursor: pointer;
    padding: 3px 25px;
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .emailicon {
    max-width: 35px;
    width: 100%;
  }

  .cpicon {
    max-height: 45px;
    margin-right: 15px;
  }

  .fblogo {
    display: block;
    width: 100%;
    max-width: 40px;
    margin: 0px;
    padding: 5px;
    margin-right: 10px;
  }

  .fbbg {
    line-height: 20px;
    font-size: 20px;
    font-family: arial;
    color: white;
    background-color: #309fd8;
    border-radius: 5px;
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
  }

}

/* END OF FRIEND CSS */


/* START OF MESSAGE  CSS */

.msgcontainer {
  width: 60%;
  margin-right: 30%;
  margin-left: 20%;
}


.msgoverflow {
  border-top: solid gray 1px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
  margin: 0px;
  padding: 10px;
}

.msgwrap {
  background-color: white;
  border: solid #309fd8 3px;
  -webkit-box-shadow: 2px 2px 10px;
  box-shadow: 2px 2px 10px;
  border-radius: 10px;
}

.msgbox {
  font-size: 18px;
  border-bottom: solid gray 1px;
  padding-top: 5px;
  padding-right: 15px;
}

.dt {
  text-align: right;
  color: gray;
  padding-right: 8px;
}



/* END OF MESSAGE  CSS */

/* START OF DISCUSSION CSS */

.discussion_container {
  margin: auto;
  background-color: white;
  border: solid #309fd8 3px;
  -webkit-box-shadow: 2px 2px 10px;
  box-shadow: 2px 2px 10px;
  border-radius: 10px;
  text-align: left;

  @media screen and (min-width: 991px) {
    width: 70%;
  }
}

.discussion_overflow {
  border-top: solid gray 1px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: left;
  max-height: 230px;
  margin: 0px;
  font-size: 18px;
}

.topic_box {
  width: 100%;
  border-bottom: solid gray 1px;
  margin: 0px;
  padding: 5px 5px 5px 15px;
  min-height: 40px;
  font-weight: bold;
  cursor: pointer;
}

.topic_img {
  height: 40px;
  width: 40px;
  margin-right: 15px;
  border-radius: 50%;
}

.topic_chatbox {
  width: 100%;
  border-bottom: solid gray 1px;
  margin: 0px;
  padding: 8px 0px 8px 0px;
  min-height: 40px;
}

.dtlsHeader {
  font-size: 20px;
  padding: 10px 0px;
}

.comment {
  margin: 0px;
  text-align: left;
  min-height: 230px;
  font-size: 18px;
  width: 100%;
  padding: 10px;
}

.SeacrhBox {
  text-align: right;
  margin: 0px;
}

.SeacrhBox input {
  width: 100%;
  margin: 0px;
}

#myMenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


#myMenu li {
  display: flex;
  align-items: center
}

#myMenu li a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  padding: 10px;
}

#myMenu li img {
  max-width: 40px;
  width: 100%;
}

.prdc_box {
  padding: 10px;
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: left;
  width: 100%;
  margin: 0px;
  border: solid gray 1px;
}

@media screen and (max-width: 1450px) {
  .SeacrhBox {
    text-align: center;
  }
}


/* END OF DISCUSSION CSS */

/* START OF FEED */

.Review_overflow {
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
  max-height: 650px;
  margin: 0px;
  font-size: 18px;

  @media screen and (min-width: 991px) {
    border-left: solid gray 1px;
  }
}

.userimg_rvw {
  width: 100%;
  border-radius: 50%;
  max-width: 130px;
  margin: 10px;
}

.review_flexbox {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: solid gray 1px;
}

.review_flexbox p {
  font-size: 16px;

  @media screen and (min-width: 991px) {
    font-size: 18px;
  }
}

.review_star {
  font-size: 33px;
  margin-top: -23px;
}

.details_prod {
  width: 100%;
  margin-right: -5px;
}

.rvrate {
  font-size: 25px;
  width: 100%;
  max-width: 100px;
  color: #309fd8;
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  border-left: solid gray 1px;
  float: right;
  justify-content: space-around;

  @media screen and (min-width: 991.1px) {
    justify-content: space-evenly !important;
  }
}

.rv_box {
  padding: 5px;
  width: 140px;
  display: flex;
  justify-content: space-between;
  
  .moon-filled {

    @media screen and (min-width: 338px) {
      margin-bottom: -60%;
    }
    @media screen and (max-width: 500px) {
      margin-bottom: -25%;
    }
    @media screen and (min-width: 500px) {
      margin-bottom: 0;
    }
  }
}

.rvrate img, .rvrate object {
  width: 100% !important;
  max-width: 50px !important;

  @media screen and (max-width: 991px) {
    width: 8vw !important;
    height: 8vw !important;
  }
}

.rvrate {
  .avg-rating {
    @media screen and (min-width: 650px) {
      font-size: 1.3rem;
    }
  }
}

.box .col-lg-6 {
  padding: 0px;
}

.viewers {
  padding: 15px;
  font-size: 24px;
  border-left: solid gray 1px;
  border-top: solid gray 1px;

}

.viewers img {
  width: 100%;
  max-width: 70px;
  margin: -5px;
}

.rvw_wraper {
  background-color: white;
  font-size: 9px;
  padding: 0px 10px 0px 10px;

  @media screen and (min-width: 991px) {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    padding: 0px 30px 0px 30px;
  }
}


.back_btn {
  color: #0051a8;
  font-size: 20px;
  padding: 0px;
  text-align: left;
  cursor: pointer;
}

.back_btn:hover {
  color: #006ce0;
  font-size: 20px;
  padding: 0px;
  text-align: left;
}


.qty {
  flex-wrap: nowrap;
  font-size: 18px;
  height: 0px;
  color: black;
  margin-top: 0px;

  @media screen and (min-width: 991px) {
    margin-top: 31px;
  }
}

.cart {
  background-color: #309fd8;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  padding: 10px;
  cursor: pointer;
  margin-top: 50px;
  // This should act like a button
  // so a disabled state is MUST.
  &.disabled, &[disabled]{
    background-color: rgb(81, 198, 253) !important;
  }
}

.box-card {

  background-color: white;

  border: solid #309fd8 3px;
  -webkit-box-shadow: 2px 2px 10px;
  box-shadow: 2px 2px 10px;
  border-radius: 10px;
}

.qty_cart {
  font-size: 20px;
  color: #309fd8;
  height: auto;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
  
  @media screen and (min-width: 991px) {
    border-left: solid gray 1px;
    margin-left: 5px;
    margin-bottom: 0;
    font-size: 25px;
    width: 150px;
  }
}

.cart_box {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  border-top: solid gray 1px;
  padding: 5px;
  display: grid;
  grid-template-columns: auto auto;

  @media screen and (max-width: 580px) {
    grid-template-columns: 100% !important;
  }

  @media screen and (min-width: 991px) {
    flex-direction: row;;
  }
}

.cart_box li {
  margin-top: 5px;
}

.rvw_wraper .col-xl-6 {
  @media screen and (min-width: 991px) {
    padding: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .cart_box {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    border-left: solid gray 1px;
    border-bottom: solid gray 1px;
    border-top: solid gray 1px;
    border-right: solid gray 1px;
    padding: 5px;
  }

  .rv_box {

    border-right: solid gray 1px;
    border-top: solid gray 1px;
    padding: 5px;
    display: flex;
    width: 140px;
    justify-content: space-between;
  }

}

@media screen and (max-width: 650px) {
  .rvrate {
    font-size: 18px;
  }

  .rvrate img {
    width: 100%;
    max-width: 20px;
  }

  .viewers img {
    width: 100%;
    max-width: 32px;
    margin: -3px;
  }

}

@media screen and (max-width: 545px) {

  .cart {
    background-color: #309fd8;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    padding: 10px;
    cursor: pointer;
    margin-top: 30px;
  }

}


/* END OF FEED */

/* START OF INTEREST */

.flowbox_container {
  width: 70%;
  margin: 10px auto;
}

.interest_header {
  background-color: white;
  border-left: solid gray 1px;
  border-top: solid gray 1px;
  border-right: solid gray 1px;
  text-align: left;
  padding: 10px;
  font-size: 16px;
  display: flex;
  justify-content: space-between
}

.interest_overflow {
  text-align: left;
  padding: 20px;
  border: solid gray 1px;
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  height: 100%;
  margin: 0px;
  font-size: 18px;
  min-height: 500px;
}

.interest_overflow img {
  width: 100%;
  max-width: 100px;
  margin: 5px;
}

.flowbox_container .col-lg-5,
.flowbox_container .col-lg-4,
.flowbox_container .col-lg-3 {
  margin-bottom: 10px;
}

.interest_button {
  background-color: #008cff;
  padding: 15px 0px;
  ;
  font-size: 20px;
  color: white;
  cursor: pointer;
  line-height: 20px;
  border-radius: 8px;
  margin: 15px;
}

.interest_button:hover {
  background-color: #10a0ff;
}


/* END OF INTEREST */

/* START OF CART CSS */

.cart_container {
  margin: auto;
  width: 100%;

  @media screen and (min-width: 991px) {
    width: 70%;
  }
}

.table_box .cart_col_1 {
  padding: 5px 15px;
  width: 100%;
  max-width: 700px;
  margin: 0px;
}

.table_box .cart_col_2 {
  padding: 5px 15px;
  width: 100%;
  max-width: 250px;
  margin: 0px;
  text-align: center
}

.table_box {
  border-bottom: 1px solid gray;
  justify-content: left;
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.cart_overflow {
  text-align: left;
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 350px;
  height: 100%;
  margin: 0px;
  font-size: 18px;
  min-height: 200px;
  background-color: white;
  border: solid #309fd8 3px;
  -webkit-box-shadow: 2px 2px 10px;
  box-shadow: 2px 2px 10px;
  border-radius: 10px;
}

.cart_prdc {
  width: 100%;
  max-width: 70px;
}

.cart_fix_header {
  border-bottom: 1px solid gray;
  background-color: white;
  text-align: left;
  font-size: 20px;
  position: sticky;
  top: 0px;
  background-color: white;
}

.receipt {
  margin-top: 10px;
  text-align: left;
  font-size: 16px;
  background-color: white;
  border: solid #309fd8 3px;
  -webkit-box-shadow: 2px 2px 10px;
  box-shadow: 2px 2px 10px;
  border-radius: 10px;
}

.breakdown {
  padding: 10px 20px;
}

.receipt_hdr {
  padding: 10px 20px;
  border-bottom: solid gray 1px;
}

.checkout_btn {
  background-color: #008cff;
  padding: 15px 0px;
  ;
  font-size: 20px;
  color: white;
  cursor: pointer;
  line-height: 20px;
  border-radius: 8px;
  margin: 15px;
  text-align: center;
  width: 100%;
  max-width: 150px;
}

.checkout_btn:hover {
  background-color: #10a0ff;
}

.rc_wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap
}

/* END OF CART CSS */

/* <!-- START OF ADDFRIEND POP-UP --> */
.addfriend_overflow {
  text-align: left;
  padding: 0px;
  border: solid gray 2px;
  border-radius: 15px;
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
  height: 100%;
  margin: 0px;
  font-size: 18px;
  min-height: 300px;
  box-shadow: 1px 3px 18px 2px black;
}

.friendwrap {
  position: relative;
}

.addfriend_box {
  position: absolute;
  top: 10px;
  padding: 0px;
  z-index: 9999;
  
  @media screen and (min-width: 991px) {
    width: 100%;
  }
}

.addfriend_header {
  border-bottom: 1px solid gray;
  background-color: white;
  text-align: left;
  font-size: 20px;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 99999;
}

.friend_search {
  margin: 10px 0px;
  width: 100%;
  max-width: 350px;
  padding: 5px 10px;
}

.srch_frd {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: flex-end;
  font-size: 24px;
  padding: 5px;
  border-bottom: solid gray 1px;
  flex-direction: column;

  @media screen and (min-width: 991px) {
    flex-direction: row;
    align-items: center;
  }
}

.srch_frd > div:first-child {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  width: 100%;

  @media screen and (min-width: 991px) {
    display: block;
    text-align: center;
    font-size: 24px;
    width: auto;
  }
}

.srch_frd img {
  width: 100%;
  max-width: 80px;
  margin: 5px;
}

.add_frd {
  background-color: rgb(0, 174, 255);
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  color: white;
  border-radius: 5px;
  padding: 5px;
  height: auto;
  width: auto;
  margin: 0px 10px;

  @media screen and (min-with: 991px) {
    width: 100%;
    max-width: 250px;
    font-size: 24px;
  }
}

.frd_close {
  position: absolute;
  z-index: 9999;
  right: 17px;
  top: 1px;
  padding: 0px;
  cursor: pointer;
}

.frd_close span {
  font-size: 41px;
  color: black;
  background-color: white;
  border-radius: 0px 3px 3px 0px;
  padding: 3px 7px;
  cursor: pointer;
}

.frnd_request {
  border: solid black 2px;
  padding: 5px;
  margin: 15px;
  font-size: 22px;
  border-radius: 10px;
  cursor: pointer;
}

.frnd_request_click {
  border: solid black 2px;
  padding: 5px;
  margin: 15px;
  font-size: 22px;
  border-radius: 10px;
  background-color: rgb(0, 174, 255);
  color: white;
}

.check_btn {
  width: 50px;
  height: 50px;
  margin-right: 5px;
  cursor: pointer;
}

.chk_box {
  display: flex;
  flex-wrap: nowrap;
  justify-items: center;
  align-items: center;
}

/* added in css */
@font-face {
  font-family: 'levenim_mtregular';
  src: url('/storage/fonts/lvnm_1.ttf') format('ttf'),
    url('/storage/fonts/lvnm_1-webfont.woff2') format('woff2'),
    url('/storage/fonts/lvnm_1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* added in css */

/* added new css */
.myrr_btn {
  border: solid #5d5d5d 1px;
  font-size: 18px;
  
  @media screen and (min-width: 991px) {
    font-size: 28px;
  }
}

.col-sm-5 b {
  color: #309fd8;
  font-size: 25px;
  word-break: keep-all !important;
}

.frd_viewer {
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;

  .icons {
    max-width: inherit;
  }
}

.frd_viewer img {
  display: block
}

.nav_bg {
  background-image: linear-gradient(#a1a1a1, lightgray 80%);
  padding: 10px 20px;

  @media screen and (min-width: 991px) {
    padding: 10px 50px;
  }
}

.srch_box {
  font-size: 16px;
  @media screen and (min-width: 991px) {
    font-size: 28px;
  }
}

/* added new css */

body {
  margin: 0;
  font-family: 'levenim_mtregular';
  /* this line edited */
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #f5f8fa;
  overflow-x: hidden;
}

.counterFriends {
  text-decoration: underline;
  margin-left: 10px;
}

/* new css for pop-up */

.popup {
  background-color: #414141;
  z-index: 6;
  padding: 20px;
  width: 82%;
  max-width: 1200px;
  border-radius: 10px;
  margin-left: 190px;
  margin-top: -102px;
}

.popup-container {
  background-color: white;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 350px;
  position: relative;
  border-radius: 10px;
  padding: 0px 20px;
}


.overflow {
  overflow-x: auto;
  max-height: 320px;
}

.triangle-left {
  position: absolute;
  left: -71px;
  top: -21px;
  width: 0;
  height: 0;
  border-radius: 10px;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 60px solid #414141;
}

.sndr-container {
  padding-top: 10px;
  justify-content: flex-end;
  display: flex;
}

.sndr-container img {
  display: inline-block;
  width: 60px;
  height: 100%;
  margin-left: 20px;
  border-radius: 50%;
  height: 60px;
}


.sndr_msg {
  display: inline-block;
  background-color: #0067ff;
  width: 75%;
  height: 100%;
  margin-top: 8px;
  padding: 10px 20px;
  color: white;
  text-align: left;
  border-radius: 5px;
}

.rcvr-container {
  padding-top: 10px;
  justify-content: flex-start;
  display: flex;
}

.rcvr-container img {
  display: inline-block;
  width: 60px;
  height: 100%;
  margin-right: 20px;
  border-radius: 50%;
  height: 60px;
}

.rcvr_msg {
  display: inline-block;
  background-color: #d3d5d8;
  width: 75%;
  height: 100%;
  margin-top: 8px;
  padding: 10px 20px;
  color: black;
  text-align: left;
  border-radius: 5px;
}

.messageContainer {

  margin-top: 13px;
}

.messageContainer input {

  width: 74%;
  height: 32px;
  border: 2px solid lightgrey;
  padding: 0px 16px;
}

.messageContainer button {
  width: 20%;
  text-align: center;
  background-color: blue;
  color: white;
  padding: 7px;
  font-weight: 700;
}

.news-feed-page {
  .line {
    @media screen and (max-width: 991px) {
      border: 0;     
    }
  }
}

.spin { 
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center;
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

/* for sm */

.custom-switch.custom-switch-sm .custom-control-label {
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.custom-switch.custom-switch-sm .custom-control-label::before {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.custom-switch.custom-switch-sm .custom-control-label::after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  border-radius: calc(1rem - (1rem / 2));
}

.custom-switch.custom-switch-sm .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(1rem - 0.25rem));
}

/* for md */

.custom-switch.custom-switch-md .custom-control-label {
  padding-left: 2rem;
  padding-bottom: 1.5rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}

/* for lg */

.custom-switch.custom-switch-lg .custom-control-label {
  padding-left: 3rem;
  padding-bottom: 2rem;
}

.custom-switch.custom-switch-lg .custom-control-label::before {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.custom-switch.custom-switch-lg .custom-control-label::after {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  border-radius: calc(3rem - (2rem / 2));
}

.custom-switch.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(2rem - 0.25rem));
}

/* for xl */

.custom-switch.custom-switch-xl .custom-control-label {
  padding-left: 4rem;
  padding-bottom: 2.5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::before {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::after {
  width: calc(2.5rem - 4px);
  height: calc(2.5rem - 4px);
  border-radius: calc(4rem - (2.5rem / 2));
}

.custom-switch.custom-switch-xl .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(2.5rem - 0.25rem));
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

.dropdown-menu {
  transition: all .5 ease-in-out;
  display: none;
  &.show {
    opacity: 0;
    display: flex;
    flex-direction: column-reverse;
    transform: translateY(-90px);
    animation: dropdown-in .3s ease-in-out;
    animation-fill-mode: forwards;
    // animation-direction: ;
  }
}

.dropdown {
  &.show {
    .dropdown-toggle::after {
      transition: all .3s ease-in-out;
      transform: rotate(180deg);
    }
  }
}

.avg-review-graphic {
  display: flex;
  flex-direction: column;

  .review-stars {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 575px) {

  .avg-review-graphic {
    flex-direction: row;

    .review-stars {
      flex-direction: row-reverse;
    }
  }

  .wrapbox {
    padding: 1rem;
  }
}


@media (max-width: 767px) {
  .interest-item {
    height: auto;
    
    .card {
      height: auto;
    }

    .text-pointer {
      float: left !important;
    }
  }
}

@keyframes dropdown-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@import './breakpoints';
$displays: block, inline, inline-block, flex, none;

@for $i from 1 through length($breakpoints) {
  $breakpointList: nth($breakpoints, $i);
  $breakpoint: nth($breakpointList, 1);
  $maxWidth: nth($breakpointList, 2);
  $minWidth: 0;

  @if $i > 1 {
    $previousBreakpoint: nth($breakpoints, $i - 1);
    $minWidth: nth($previousBreakpoint, 2) + .1px;
  }

  @each $display in $displays {
    .d-#{$breakpoint}-#{$display} {
      @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
        display: #{$display} !important; 
      }
    }
    .d-min-#{$breakpoint}-#{$display} {
      @media screen and (min-width: $minWidth) {
        display: #{$display} !important; 
      }
    }
    .d-max-#{$breakpoint}-#{$display} {
      @media screen and (max-width: $maxWidth) {
        display: #{$display} !important; 
      }
    }
  }
}

.category-search {
  z-index: 100;
  border-radius: 5px;
  max-height: 400px;
  overflow: auto;

  @media screen and (max-width: 991px) {
    top: 75px;
    left: 0px;
    max-width: 97vw;
  }
}

.reply-controls {
  @media screen and (max-width: 365px) {
    flex-direction: column !important;
    align-items: initial;

    .button {
      margin-left: 0 !important;
      margin-top: .5rem;
      width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  .rRbutton {  
    font-size: 36px !important;
  }

  .mobile-nav {
    height: 80px;
  }
}

.mobile-nav {
  .myrr_btn {
    @media screen and (max-width: 364px) {
      padding: 0.3rem !important;
    }
  }
}

.mobile-search-icon {
	display: flex;
	background: #359fd8;
	height: 45px;
	width: 45px;
	position: fixed;
	border-radius: 50%;
	right: 3vw;
	top: 10px;
	font-weight: 900;
	justify-content: center;
	align-items: center;
	z-index: 99999999;
	border: 1px solid rgba(0, 0, 0, .1);
	color: #fff;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  cursor: pointer;
  
  @media screen and (min-width: 577px) {
    display: none;
  }

  &:hover {
    background: #3494c7 !important;
  }

  &::after {
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #fff !important;
    animation-name: search-icon-animation;
    animation-timing-function: ease-in-out;
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }

  &.active {
    &::after {
      animation-name: search-icon-close-animation;
      animation-timing-function: ease-in-out;
      animation-duration: .5s;
      animation-fill-mode: forwards;
    }
  }
}

.search-section {
  @media screen and (max-width: 576px) {
      display: none;
      position: fixed;
      top: 40px;
      right: calc(3vw + 48px);
      z-index: 999;
      background: #fff;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      width: 85%;
      transform-origin: top right;
      border: 1px solid rgba(0, 0, 0, .2);
      border-radius: 5px;

      &.mobile-shown {
        display: block;
        opacity: 0;
        animation-name: mobile-search-animation;
        animation-timing-function: ease-in-out;
        animation-duration: .5s;
        animation-fill-mode: forwards;
      }
  }
}

@keyframes search-icon-animation {
  0% {
    transform: scale(1);
    content: "\f00d";
  }
  50% {
    transform: scale(0);
    content: "\f002";
  }
  100% {
   content: "\f002";
    transform: scale(1);
  }
 }

 @keyframes search-icon-close-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
    content: "\f00d";
  }
  100% {
   content: "\f00d";
    transform: scale(1);
  }
 }

 @keyframes mobile-search-animation {
   0% {
     transform: translateX(60px) scale(0);
     opacity: 0;
   }
   100% {
    transform: translateX(0px) scale(1);
    opacity: 1;
   }
 }

 .menubar {
   .item {
     font-size: calc((17vw - 4.5rem) / 7);

     @media screen and (min-width: 768px) and (max-width: 992px) {
       font-size: calc((24vw - 4.5rem) / 7) !important;
     }

     
    @media screen and (max-width: 766px) {
      font-size: 16px !important;
    }
   }
 }

.mobile-search-row {
  display: none !important;

  @media screen and (max-width: 576px) {
    display: flex !important;
    background: #5d5d5d;

    .search-button {
      display: flex;
      background:rgb(0, 174, 255);
      border-radius: 20px;
    }

    .label {
      background-color: #fff;
      width: 100%;
      border-color: rgb(0, 174, 255);
      border-style: solid;
      border-width: 5px 5px 5px 20px;
      border-image: none 100% / 1 / 0 stretch;
      border-radius: 20px 0px 0px 20px;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
      font-size: 17px;
      font-weight: 600;
      cursor: pointer;
      height: 40px;
      display: flex;
      align-items: center;
      padding-left: .75rem;
      padding-right: 3rem;
    }

    &.hide {
      .label {
        animation: hide-search-label .5s ease-out;
        animation-fill-mode: backwards;
        animation-direction: reverse;
      }
    }
  
    .btn {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      padding-left: .1rem;
      &:hover, &:focus, &:active, &:focus-within {
        background-color: rgb(0, 174, 255) !important;
        box-shadow: none !important;
        border: 0 !important;
      }

      &::after {
        content: "\f002";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: #fff !important;
      }
    }

    &.show {
      .label {
        animation: hide-search-label 1s cubic-bezier(.37,.78,.21,.53);
        animation-fill-mode: forwards;
      }

      .btn {
        animation: search-row-btn 300ms ease-out;
        animation-delay: 400ms;
        animation-fill-mode: forwards;

        &::after {
          animation: search-row-icon 300ms ease-out;
          animation-delay: 450ms;
          animation-fill-mode: forwards;
        }
      }
    }

    &.shown {
      .label {
        display: none !important;
      }
    }
  }
}

@keyframes search-row-btn {
  0% {
    padding-left: 1.6px;
    padding-right: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 20px;
    width: auto;
    transform: rotate(120deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    width: 35px;
    border-radius: 50%;
    padding-right: 0;
    padding-left: 0;
    transform: rotate(360deg);
  }
}

@keyframes search-row-icon {
  0% {
    content: "\f002";
  }
  50% {
    transform: scale(.5);
    opacity: 0;
    content: "\f00d";
  }
  100% {
    content: "\f00d";
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes hide-search-label {
  0% {
    font-size: 100%;
    width: 100%;
    padding-right: 3rem;
    padding-left: .75rem;
  }
  100% {
    font-size: 0%;
    width: 0;
    padding-right: 0;
    padding-left: 0;
    opacity: 0;
  }
}

.nav-feed-link {
  @media screen and (max-width: 576px) {
    position: relative;
    top: -10px;
  }
}

.interests-container {
  @media screen and (max-width: 767.68px) {
    overflow-y: visible;
  }
}

.custom-scrollbar {
  position: relative !important;
  overflow: hidden !important;

  @media screen and (max-width: 992px) {
    overflow: auto !important;
  }
  .rail--y {
    content: "";
    position: absolute;
    top: 0;
    right: 14px;
    bottom: 0;
    width: 14px;
    background-color: rgba(0, 0, 0, .1);
    z-index: 1;
    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .custom-scrollbar--y {
    width: 14px;
    height: 20px;
    right: 0;
    background-color: #6a6a6a;
    z-index: 2;
    opacity: .9;
    cursor: pointer;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .custom-scrollbar-wrapper {
    overflow: hidden;
    height: 100%;
    @media screen and (max-width: 992px) {
      overflow: auto;
    }
  }
}


.interests-container {
  @media screen and (max-width: 576px) {
    width: 80%;
    margin-left: 10%;
  }
}

.interests-scroll-buttons {
  display: none;

  @media screen and (max-width: 576px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    right: -3%;
  }
  @media screen and (max-width: 421px) {
    right: -5%;
  }

  .btn {
    height: 50px;
    width: 50px;
    font-size: 10px;
    border-radius: 50%;
    padding: 0;
    transition: all .2s ease-in-out;

    &:active {
      transform: scale(.9);
    }

    .fa {
      display: block;
      font-size: 12px;
    }
  }
}

.product-description {
  position: relative;
  height: calc(31px * 4);
  overflow: hidden;

  @media screen and (max-width: 992px) {
    height: calc(23px * 4);
  }

  &.read-more{
      &::after {
      content: "[...]";
      display: block;
      position: absolute;
      right: 0;
      bottom: .2rem;
      background: #fff;
      padding-right: .3rem;
    }
  }
}

#nprogress .bar {
  height: 4px;
}