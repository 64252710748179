.interest-lists, .selected-interest-lists{
    height: 450px;
    overflow-y: auto;
}

.interest-item {
    position: relative;

    .card {
        height: 120px;
        overflow: hidden;
        // background-repeat: no-repeat;
        // background-size: 80px;
        // background-position: center center;
        cursor: pointer;
        margin-bottom: 20px;
        border: none;

        .card-header {
            word-break: break-word;
        }

        .card-body {
            word-break: break-word;
        }

        .interest-toggle {
            background: #ccc;
            border-radius: 50%;
            color: #333;
            padding: 2px 6px;
            display: inline-block;
            position: absolute;
            right: 10px;
            top: 10px;
        }


        h4 {
            text-align: center;
            padding: 0 0px;
            position: relative;
            left: 0;
            font-size: .67em;
            width: 100%;
            z-index: 10;
            word-break: break-word;
        }
    }
}

.selected-interest-lists {
    word-break: break-word;

    @media screen and (max-width: 768px) {
        .interest-item {
            .card {
                height: auto;

                img {
                    width: 100%;
                }
            }
        }
    }
    @media screen and (min-width: 991px) {
        .interest-item {
            left: -.1vw;
        }
    }
}

// .selected-interest-lists {
//     .card {
//         padding: 0;
//         overflow: hidden;

//         .card-body {
//             z-index: 1;
//             position: relative;

//             img {
//                 height: 70px;
//                 position: absolute;
//                 top: -20%;
//                 left: 0;
//             }

//             h4 {
//                 position: absolute;
//                 top: 50px;
//                 left: 50%;
//             }
//         }
//     }
// }

.interests-container {
    max-height: 600px;
    overflow: hidden;

    > .card-body {
        overflow: auto;
    }
}

.submit-new-interests {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

#profile-interests {
    @media (max-width: 767px) {
        max-width: 100%;
    }    
}

.profile-info {
    .field {
        @media (max-width: 767px) {
            width: 50%;
            margin-top: .5rem;
        }
    }
}