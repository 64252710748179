.rr-badge-container {
    position: relative;
    display: inline-block;
    margin-top: 15px;
}

.rr-badge {
    position: absolute;
    left: -20px;
    top: -15px;
    border-radius: 50%;
    background:#00aeff;
    color: #FFFFFF;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.rr-badge--inline {
        width: 26px;
        height: 26px;
        font-size: 14px;
    }
    
    &.rr-badge--inline {
        position: static;
        left: 0;
        top: 0;
        vertical-align: middle;
    }
}