.button {
  background-color: rgb(0, 174, 255);
  border: solid white 1px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  padding: 4px 7px;
  cursor: pointer;

  @media screen and (min-width: 576px) {
    font-size: 20px;
    padding: 4px 7px;
  }

  &-p7 {
    padding: 7px;
  }

  &-blk {
    border: #434343 1px solid;
    -webkit-box-shadow: #434343 2px 2px 2px;
    box-shadow: #434343 2px 2px 2px;
  }

  &-md {
    font-size: 14px;
    padding: 2px 10px;
  }

  &-sm {
    font-size: 10px;
  }
}

